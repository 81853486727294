<template>
  <q-card style="width: 600px; max-width: 60vw">
    <q-bar class="bg-primary text-white">
      <q-icon name="fas fa-keyboard" />
      <div>
        用户订单主表
        <small class="q-ml-sm text-size-sm">
          {{ PdmOrderEntity.PdmOrderName }}({{ PdmOrderEntity.PdmOrderID }})
        </small>
      </div>

      <q-space />

      <q-btn dense flat icon="close" v-close-popup>
        <q-tooltip>关闭</q-tooltip>
      </q-btn>
    </q-bar>

    <q-card-section class="q-pa-sm">
      <div class="row q-gutter-sm q-mb-sm">
        <q-input
          label="微信小程序用户ID"
          dense
          outlined
          class="col-2"
          v-model="PdmOrderEntity.UserInfo_ID"
        />
        <q-input
          label="地址表ID：PDM_WX_UserAddress"
          dense
          outlined
          class="col-2"
          v-model="PdmOrderEntity.AddressID"
        />
        <q-input
          label="机构ID"
          dense
          outlined
          class="col-2"
          v-model="PdmOrderEntity.HospID"
        />
        <q-input
          label="收货人名称"
          dense
          outlined
          class="col-2"
          v-model="PdmOrderEntity.UserName"
        />
        <q-input
          label="收货人电话"
          dense
          outlined
          class="col-2"
          v-model="PdmOrderEntity.TelPhone"
        />
        <q-input
          label="收货人地址"
          dense
          outlined
          class="col-2"
          v-model="PdmOrderEntity.Address"
        />
        <q-input
          label="是否审核通过：0未审核，1已审核"
          dense
          outlined
          class="col-2"
          v-model="PdmOrderEntity.IsAudit"
        />
        <q-input
          label="是否发货：0未发货，1已发货"
          dense
          outlined
          class="col-2"
          v-model="PdmOrderEntity.IsDelivery"
        />
        <q-input
          label="添加时间"
          dense
          outlined
          class="col-2"
          v-model="PdmOrderEntity.AddDate"
        />
      </div>
    </q-card-section>

    <q-card-actions align="right" class="bg-white text-teal">
      <q-btn flat label="取消" v-close-popup />
      <q-btn flat label="保存并审核通过" @click="onOK(1)" />
      <q-btn flat label="保存" @click="onOK(0)" />
    </q-card-actions>
  </q-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  components: {},
  props: {},
  computed: {
    ...mapState("PdmOrder", [
      "PdmOrderShowDlg",
      "PdmOrderEntity",
      "PdmOrderList",
    ]),
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    ...mapActions("PdmOrder", ["actUpdatePdmOrder", "actGetPdmOrderList"]),
    onOK(val) {
      if (val == 1) {
        this.PdmOrderEntity.IsAudit = 1;
      }
      this.actUpdatePdmOrder({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: this.PdmOrderEntity,
      }).then((res) => {
        if (res.Code == 0) {
          setTimeout(() => {
            this.$emit("onLoadData");
          }, 500);
          this.msg.success(res.Message);
        } else {
          this.msg.error(res.Message);
        }
      });
    },
  },
};
</script>
